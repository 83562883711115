@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    color-scheme: normal !important;
}

@layer utilities {
    .pb-safe {
        padding-bottom: env(safe-area-inset-bottom);
    }

    .pt-safe {
        padding-top: env(safe-area-inset-top);
    }

    .py-safe {
        padding-top: env(safe-area-inset-top);
        padding-bottom: env(safe-area-inset-bottom);
    }

    .px-safe {
        padding-left: env(safe-area-inset-left);
        padding-right: env(safe-area-inset-right);
    }

    .pl-safe {
        padding-left: env(safe-area-inset-left);
    }

    .pr-safe {
        padding-right: env(safe-area-inset-right);
    }
}